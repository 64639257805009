<template>
  <side-nav></side-nav>
  <div class="content">
    <router-view></router-view>
  </div>  
</template>

<script>
import SideNav from './components/SideNav.vue'

export default {
  name: 'App',
  components: {
    SideNav,
  }
}
</script>

<style>
html, body{
  min-height: 100%;
  background-color: #d7d7d7;
  margin: 0px;
}

#app {
  font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100%;
}
</style>
