<template>
  <div class="side-nav">
    <div class="sidebar">
      <img src="../assets/BuurtschapLogo150x150.svg" alt="Buurtschap de vallei" style="width: 200px; height: 200px; margin: 1rem 0rem; background-color: white;"/>
      <router-link to="/" :class="{'active': path === '/'}">Home</router-link>
      <router-link to="/leden-administratie" :class="{'active': path === '/leden-administratie'}">Ledenadministratie en Privacy</router-link>
      <router-link to="/algemene-leden-vergaderingen" :class="{'active': path === '/algemene-leden-vergaderingen'}">Algemene Ledenvergaderingen</router-link>
      <router-link to="/evenementen" :class="{'active': path === '/evenementen'}">Evenementen</router-link>
      <router-link to="/werkgroepen-en-kascommissie" :class="{'active': path === '/werkgroepen-en-kascommissie'}">Werkgroepen en Kascommissie</router-link>
      <router-link to="/speelroute-veld15" :class="{'active': path === '/speelroute-veld15'}">Speelroute Veld 15</router-link>
      <router-link to="/contact" :class="{'active': path === '/contact'}">Contact</router-link>
    </div>
  </div>
  
</template>

<script>
import {useRoute} from 'vue-router'
import {computed} from 'vue'

export default {
  name: 'SideNav',
  setup() {
    const route = useRoute();

    const path = computed(() =>route.path)

    console.log(path.value, 'PATH');
    return {
      path
    }
  },
}
</script>

<style>
.sidebar {
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: #7BAA86;
  position: fixed;
  height: 100%;
  overflow: auto;
}

.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}
 
.sidebar a.active {
  background-color: #635046;
  color: white;
}

.sidebar a:hover:not(.active) {
  background-color: white;
  color: black;
}

div.content {
  margin-left: 200px;
  padding: 2rem 1rem 0rem 1rem;
  height: 100%;
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {float: left;}
  div.content {margin-left: 0;}
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}
</style>